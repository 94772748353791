<script>
/**
 * Select input with search.
 *
 * @displayName Select Serach
 * @version 1.0.0
 * @since
 */

import uuid from 'uuid/v4';
import httpService from '@/api/http';
import { debounce, isObject } from '@/util/utils';
import flash from '@/components/ui/FlashMessage';
import VLabel from '@/components/v3/elements/VLabel';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzButton from '@/components/ui/Button/EzButton';
import { mapMutations } from 'vuex';

export default {
  name: 'VSelectSearch',
  components: {
    EzLoader,
    VLabel,
    EzButton,
  },
  props: {
    /**
     * Select input name.
     */
    name: {
      type: String,
      required: false,
    },
    /**
     * Initial data to populate the select
     */
    data: {
      type: Array,
      required: true,
    },
    formKey: {
      type: String,
      required: false,
    },
    /**
     * Provide a data source to fetch the results.
     * If the value is set the false the search will be done in-place
     * with existing data.
     */
    search: {
      type: [Function, String, Boolean],
      required: false,
      default: false,
    },
    /**
     * If API is not used as a source search value is required.
     * This is the property used to search inside the object.
     */
    searchProperty: {
      type: String,
      required: false,
      default: 'name',
    },
    valueProperty: {
      type: String,
      required: false,
      default: 'id',
    },
    /**
     * Input placeholder.
     */
    placeholder: {
      type: String,
      default: 'Search',
    },
    /**
     * Search Input placeholder.
     */
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    /**
     * Input label.
     */
    label: {
      type: String,
    },
    /**
     * If the input is disabled or not
     */
    disable: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom render function for a different icon.
     */
    icon: {
      type: Function,
    },
    /**
     * Additional request headers.
     */
    requestParams: {
      type: Object,
    },
    /**
     * Minimum length of the search string before search is done.
     */
    minLength: {
      type: Number,
      default: 0,
    },
    /**
     * Time to debounce typing before sending the request.
     */
    debounceTime: {
      type: Number,
      default: 300,
    },
    /**
     * Transformer function to run the results through.
     * Useful if you want to additionally transform the result.
     */
    transformer: {
      type: Function,
    },
    /**
     * Preselected the value in data.
     * If a string is given it will match it with `valueProperty` in data.
     * If an object is given it will directly add it as a selected item.
     */
    selected: {
      type: [String, Object, Number],
      required: false,
    },
    /**
     * Align dropdown to left.
     * Default value is right.
     */
    alignLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasClear: {
      type: Boolean,
      required: false,
      default: true,
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    scrollIntoView: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    searchLimit: {
      type: Number,
      default: 10,
    },
    stopPropagation: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      id: null,
      results: [],
      source: [],
      isLoading: false,
      isFocused: false,
      hasError: false,
      input: '',
      selectedIndex: 0,
      initialSelectedValue: 0,
      selectedItem: {},
      showDropdown: false,
      eventListeners: false,
    };
  },
  computed: {
    query() {
      return this.input.toLowerCase();
    },
    hasMinLengthQuery() {
      return this.input.length >= this.minLength || this.input.length === 0;
    },
    hasResultSlot() {
      return !!this.$scopedSlots.result;
    },
    hasFirstSlot() {
      return !!this.$scopedSlots.firstResult;
    },
    isEmptyState() {
      return !this.hasError && !this.hasResults && !this.isLoading && this.isFocused;
    },
    hasSelectedValue() {
      return Object.keys(this.selectedItem || {}).length > 0;
    },
    /**
     * Given an outside transformer, transform the results.
     * @return {[]|*}
     */
    transformedResults() {
      if (!this.transformer) return this.results;
      return this.transformer(this.results);
    },
    selectedValue() {
      return this.selectedItem?.[this.valueProperty];
    },
    serverError() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    hasServerErrorMessage() {
      if (!this.serverError || typeof this.serverError !== 'object') return false;
      return Object.prototype.hasOwnProperty.call(this.serverError, 'message');
    },
  },
  created() {
    this.id = uuid();
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.results = this.data;
        this.source = this.data;
        this.preSelect();
      },
    },
    selected() {
      this.preSelect();
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERROR']),
    /**
     * Given a selected prop, preselect.
     */
    preSelect() {
      if (!this.selected) {
        this.selectedItem = {};
      }

      if (isObject(this.selected)) {
        this.selectedItem = this.selected;
        return;
      }

      const found = this.data.find(o => o[this.valueProperty] === this.selected);
      if (found) this.selectedItem = found;
    },
    isSelected(index) {
      return index === this.selectedIndex;
    },
    searchFn() {
      if (!this.hasMinLengthQuery) return [];
      if (this.search) return this.requestSearch();
      return this.arraySearch();
    },
    requestSearch: debounce(function deb() {
      if (!this.hasMinLengthQuery) return;
      this.isLoading = true;
      this.request();
    }, 300),

    async request() {
      try {
        const { data } = await httpService.get(this.search, {
          params: {
            term: this.query,
            limit: this.searchLimit,
            connected: false,
            ...this.requestParams,
          },
        });

        this.results = data.data;
        this.onResult();
        this.isLoading = false;
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
        });
        this.isLoading = false;
      }
    },

    arraySearch() {
      if (!this.query) {
        this.results = this.source;
      } else {
        this.results = this.source.filter(item => this.getSearchValue(item).includes(this.query));
      }
      this.onResult();
    },

    deepFind(obj, search) {
      const searchArr = typeof search === 'string' ? search.split('.') : search;

      if (searchArr.length === 1) return obj[searchArr[0]];
      return this.deepFind(obj[searchArr[0]], searchArr.slice(1));
    },

    getSearchValue(obj) {
      return this.deepFind(obj, this.searchProperty).toLowerCase();
    },

    onResult() {
      this.selectedIndex = 0;
      this.$emit('results', { results: this.results });
      this.isLoading = false;
    },
    up() {
      this.selectedIndex = (this.selectedIndex + this.results.length - 1) % this.results.length;
      this.adjustScroll();
    },
    down() {
      this.selectedIndex = (this.selectedIndex + 1) % this.results.length;
      this.adjustScroll();
    },
    enter() {
      this.select(this.results[this.selectedIndex]);
    },
    select(obj) {
      if (!obj) return;
      /**
       * When an item is selectedItem
       */
      this.$emit('selected', obj);
      this.selectedItem = obj;
      if (this.closeOnSelect) this.close();
      this.$refs.input.blur();
    },
    reset() {
      this.selectedItem = {};
      this.clear();
      this.$emit('selected', { selected: null, query: '', reset: true });
    },
    clear() {
      this.input = '';
      this.error = null;
      this.selectedIndex = 0;
    },
    async open() {
      this.showDropdown = true;
      this.setupEventListeners();
      await this.$nextTick();
      this.focus();
      this.$emit('open');

      const idx = this.results.findIndex(
        res => res[this.valueProperty] === this.selectedItem[this.valueProperty],
      );
      this.selectedIndex = idx < 0 ? 0 : idx;
    },
    close() {
      this.showDropdown = false;
      this.clear();
      if (this.hasServerErrorMessage) {
        this.CLEAR_ERROR({
          formKey: this.formKey,
          field: this.name,
        });
      }
      this.removeEventListeners();
      this.results = this.source;
      this.$emit('close');
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    focus() {
      this.$refs.input.focus();

      if (!this.scrollIntoView) {
        return;
      }
      this.$nextTick().then(() => {
        this.$refs.input.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    },
    clickOutsideListener(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.close();
      }
    },
    /**
     * Move the scroll based on the highlighted item
     */
    adjustScroll() {
      const { dropdownList } = this.$refs;
      const hoveredItem = this.$refs.dropdownList.children[this.selectedIndex];
      const searchInputHeight = this.$refs.input.parentElement?.getBoundingClientRect().height || 0;
      const bounds = dropdownList
        ? dropdownList.getBoundingClientRect()
        : {
            height: 0,
            top: 0,
            bottom: 0,
          };
      const { top, bottom, height } = hoveredItem
        ? hoveredItem.getBoundingClientRect()
        : {
            height: 0,
            top: 0,
            bottom: 0,
          };
      if (bottom > bounds.bottom) {
        // Go down
        dropdownList.scrollTop = (hoveredItem?.offsetTop || 0) - (bounds.height - height);
      }
      if (top < bounds.top + searchInputHeight) {
        // Go up
        // 10px = margin-top (8px) + border (1px)
        dropdownList.scrollTop = (hoveredItem?.offsetTop || 0) - searchInputHeight + height - 10;
      }
    },
    setupEventListeners() {
      if (this.eventListeners) return;
      this.eventListeners = true;
      document.addEventListener('click', this.clickOutsideListener, true);
    },
    removeEventListeners() {
      this.eventListeners = false;
      document.removeEventListener('click', this.clickOutsideListener, true);
    },
    onClick(e) {
      if (this.stopPropagation) e.stopPropagation();
    },
  },
};
</script>
<template>
  <div
    @click="onClick"
    :class="[
      'select-search',
      { 'select-search--disabled': disable },
      { 'width-100': isFullWidth },
      { error: serverError },
    ]"
  >
    <v-label v-if="label" class="label" :for="id">
      {{ label }}
    </v-label>
    <div class="hidden" aria-hidden="true">
      <select
        v-model="selectedValue"
        :name="name"
        :id="id"
        ref="select"
        tabindex="-1"
        :disabled="disable"
      >
        <option
          v-for="result in results"
          :key="result[valueProperty]"
          :value="result[valueProperty]"
        >
          {{ deepFind(result, searchProperty) }}
        </option>
      </select>
    </div>

    <div :class="['select-search__wrapper', { 'select-search__wrapper--disabled': disable }]">
      <button
        @click="open"
        type="button"
        :class="['select-search__trigger', { focused: showDropdown }, { 'width-100': isFullWidth }]"
        :data-cy="dataCy"
      >
        <slot
          name="value"
          v-if="hasSelectedValue && selectedItem.id !== null"
          :value="selectedItem"
        >
          <span class="select-search__value">
            {{ deepFind(selectedItem, searchProperty) }}
          </span>
        </slot>
        <span v-else>{{ placeholder }}</span>
        <font-awesome-icon v-if="showDropdown" icon="angle-up" />
        <font-awesome-icon v-else icon="angle-down" />
      </button>

      <div
        v-show="showDropdown"
        ref="dropdown"
        :class="[
          'select-search__dropdown',
          { 'select-search__dropdown--left': alignLeft },
          { 'select-search__dropdown--right': !alignLeft },
          { 'select-search__dropdown--width-100': isFullWidth },
        ]"
      >
        <div class="select-search__search-wrapper">
          <div class="input-wrapper">
            <input
              class="select-search__search"
              ref="input"
              type="text"
              v-model="input"
              :placeholder="searchPlaceholder"
              :disabled="disable"
              @input="searchFn"
              @keydown.enter="enter"
              @keydown.tab="close"
              @keydown.up="up"
              @keydown.down="down"
              @keydown.esc="close"
              @focus="onFocus"
              @blur="onBlur"
            />
            <font-awesome-icon icon="search" />
          </div>
        </div>
        <div class="select-search__list-wrapper">
          <ez-loader :show="isLoading" />
          <ul class="select-search__list" ref="dropdownList">
            <li v-if="hasSelectedValue && hasClear" class="select-search__item clear">
              <ez-button
                @click="reset"
                type="blue-link"
                :class="['clear-btn', { 'default-list': !hasResultSlot }]"
              >
                Clear Selection
              </ez-button>
            </li>
            <template v-if="!hasResultSlot">
              <li
                v-for="(result, index) in transformedResults"
                :data-cy="`${dataCy}-result-${index}`"
                :key="result[valueProperty]"
                :class="['select-search__item', { selected: isSelected(index) }]"
                @click.prevent="select(result)"
              >
                {{ deepFind(result, searchProperty) }}
              </li>
            </template>
            <template v-else>
              <li
                v-if="hasFirstSlot"
                :class="['select-search__item', 'custom-first-item', { selected: isSelected(-1) }]"
                @click.prevent="select({}, { first: true })"
              >
                <slot name="firstResult"></slot>
              </li>
              <li
                v-for="(result, index) in transformedResults"
                :data-cy="`${dataCy}-result-${index}`"
                :key="result[valueProperty]"
                :class="['select-search__item', { selected: isSelected(index) }]"
                @click.prevent="select(result)"
              >
                <slot name="result" :result="result"></slot>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="hasServerErrorMessage" class="select-search__error">
      {{ serverError.message }}
    </div>
  </div>
</template>
<style lang="scss" scoped>
$minWidth: 200px;
$triggerPadding: 10px;

.select-search {
  display: inline-block;

  &__error {
    margin-top: 0.5rem;
    color: $input-border-error-color;
    @include font-size(12px);
  }

  &.error {
    .select-search__trigger {
      border-color: $input-border-error-color;
    }
    :deep() label.label {
      color: $input-border-error-color;
    }
  }

  &__wrapper {
    position: relative;
    text-align: left;
    cursor: pointer;
  }

  &__search-wrapper,
  &__trigger {
    display: inline-flex;
    align-items: center;
    .input-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  &__search-wrapper {
    width: 100%;
    padding: 0 10px;
    border-bottom: 1px solid $color-gray-E9;
  }

  &__trigger {
    justify-content: space-between;
    background-color: $color-gray-F5;
    border-radius: $border-radius;
    min-width: $minWidth;
    height: 36px;
    padding: 0 $triggerPadding;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 0.3s ease-in-out;

    &.focused,
    &:focus {
      background-color: #fff;
      border: 2px solid $color-primary-dark-blue;
    }
  }

  &__dropdown {
    margin-top: 8px;
    width: 280px;
    border: 1px solid #dee1e4;
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.12);
    @include z-index('autocomplete');
    @include absolute(top 100%);

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__search {
    @extend %input-reset;
    padding-right: 12px;
    display: block;
    width: 100%;
    height: 36px;

    &:focus {
      outline: none;
    }

    + svg {
      color: $color-primary-dark-blue;
    }
  }

  &__list-wrapper {
    position: relative;
  }

  &__list {
    @extend %ul-reset;
    max-height: 144px;
    overflow-y: auto;
    box-shadow: 0 8px 6px -4px rgba(0, 0, 0, 0.12);

    li {
      padding: 6px 12px;
    }

    .custom-first-item {
      padding-top: 0;
      padding-bottom: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__item {
    padding: 10px;

    &:hover,
    &.selected {
      background-color: $color-gray-F5;
    }
  }

  .clear {
    border-bottom: 1px solid $color-gray-E9;
    .clear-btn {
      padding: 0;
      height: 31px;
      @extend %flex-center;
    }

    .default-list {
      height: 15px;
    }
  }

  &__value {
    color: #2b2d3e;
    overflow: hidden;
    width: calc(#{$minWidth} - (10px + 2 * #{$triggerPadding}));
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }

  :deep() label {
    margin-bottom: 6px;
    line-height: 14px;
  }
}

.select-search__dropdown--width-100 {
  width: 100%;
}

$input-disabled-bg-color: #e8ecf7;
$input-disabled-color: #b4b8c2;

.select-search--disabled {
  cursor: not-allowed;

  .select-search__value {
    color: $input-disabled-color;
  }
}

.select-search__wrapper--disabled {
  pointer-events: none;

  button {
    background-color: $input-disabled-bg-color;
  }
  svg {
    color: $input-disabled-color;
  }
}
</style>
